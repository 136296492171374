<template>
    <div class="main orte-index">
        <div class="main-sidebar">
            <div class="main-sidebar__head">
                <h4 class="title">{{$t('ort.title')}}</h4>
                <div class="btn-group">
                    <router-link @click.native="expandFilter = true" to="/orte" :class="['btn', showMap ? 'btn-active' : '']">{{$t('ort.map')}}</router-link>
                    <router-link @click.native="expandFilter = false" to="/orte?show=liste" :class="['btn', !showMap ? 'btn-active' : '']">{{$t('ort.list')}}</router-link>
                </div>
            </div>

            <div v-if="tempShowHide" class="main-sidebar__filter">
                <div v-if="!expandFilter && !$isMobileScreen">
                    <keyword-search class="mb20" :focusMe="true" :initialValue="dataSet.query.keyword" @searchWordChanged="searchWordChanged"/>

                    <anbieter-filter v-if="contentType!==null && sublocalityFilter!==null" :contentType="contentType" :selectedFilters="selectedFilters" :structuredFilters="structuredFilters" :sublocalityFilter="sublocalityFilter" @filterChanged="setQueryFilters" @resetAllFilters="resetAllFilters"/>

                    <div class="clearfix"></div>
                </div>
                <div v-if="showMap">
                    <button @click="expandFilter = false" v-if="expandFilter" class="btn btn-expand btn-link" ref="searchExpand">{{$t('ort.filter-down')}}</button>
                    <button @click="expandFilter = true" v-else class="btn btn-expand btn-link">{{$t('ort.filter-up')}}</button>
                </div>
            </div>
            <div v-if="showMap">
                <div tabindex="-1" class="main-sidebar__content" v-if="dataSet.data!==null && dataSet.data.length>0 && showMap">
                    <!-- data will be here -->
                    <h6>{{ dataSet.total }} {{$t('merkliste.results')}}</h6>
                    <orte-card v-for="(item, index) in dataSet.data" :key="index" target="map" :showMore ="true"  :item="item" @highlight="highlightThisContent" :id="'vertical-content-'+item.id"/>
                </div>

                <div v-else class="search-no-results">
                    <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                    <h5 class="mt20">{{$t('angebote.noresult')}}</h5>
                </div>
            </div>

        </div>

        <div class="main-content" tabindex="-1">
            <TitleBar :initialContentType="$t('angebote.title')" v-if="contentType!==null && $isMobileScreen" 
            karteLink="/orte" :filters="allFilters" :selectedFilters="selectedFilters" 
            listeLink="/orte?show=liste" :showMapOrList="showMap" :isFixed="true" :showButtons="true" :contentCount="tempContentCount" 
            :initialKeywordSearchValue="dataSet.query.keyword" @contentFilteringFinished="filteringFinished"
            @resetAllFilters="resetAllFilters" @getNumberOfResults="getNumberOfResults" @searchWordChanged="searchWordChanged"/>

            <div v-if="showMap" class="karte">

                <multiple-map ref="theMap" class="the-karte" :contents="dataSet.data" @highlight="highlightThisContent"/>

                <div class="karte-cards" v-if="dataSet.data!==null && dataSet.data.length>0 && $isMobileScreen">
                    <div class="container-fluid">
                        <div class="row mobile--scrolling horizontal-scroll">
                            <map-card v-for="(item, index) in dataSet.data" :key="index" :item="item" @highlight="highlightThisContent" :id="'horizontal-content-'+item.id"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="list-section">
                <div class="container-fluid">
                    <div class="row" v-if="dataSet.data!==null && dataSet.data.length>0">
                        <div class="col-12 col-sm-12 col-md-12 col-xl-4 cards" v-for="(item, index) in dataSet.data" :key="index">
                            <orte-card :item="item"/>
                        </div>
                    </div>

                    <div v-else class="search-no-results">
                        <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                        <h5 class="mt20">{{$t('angebote.noresult')}}</h5>
                    </div>
                    
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { getCtype } from '@/api/contentType';
import Resource from '@/api/resource';
const contentTypeResource = new Resource('contenttypes');
import { getSublocalities } from '@/api/system';
import { search } from '@/api/content';
import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, getStructuredFilters, getBasicFilters, argsFilterMapToJSMap, isset } from '@/utils/helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import VueScrollTo from 'vue-scrollto';

export default {
    name: "Orte",
    mixins: [screenSizeMixin],
    components: {
        MapCard: () => import('./components/OrteMapCard.vue'),
        TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
        OrteCard: () => import('@/components/cards/Ort.vue'),
        MultipleMap: () => import('@/components/map/multiple.vue'),
        AnbieterFilter: () => import('@/components/filters/anbieter.vue'),
        WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
        DependencyFilterStructure: () => import('@/components/controls/DependencyFilterStructure.vue'),
        MainCategorySelect: () => import('@/components/controls/MainCategorySelect.vue'),
        MainCategorySelectModal: () => import('@/components/modals/MainCategorySelectModal.vue'),
        KeywordSearch: () => import('@/components/controls/KeywordSearch'),
    },
    data() {
        return {
            tempShowHide: true,
            showMainCategorySelectModal: false,
            currentContentTypeId: this.$institutionId,
            tempContentCount: 0,
            contentType: null,
            sublocalities: null,
            isMobileView: false,
            dataSet: {
              total: 0,
              data: [],
              meta:null,
              query: {
                page:1,
                limit: 500,
                filters: null,
                view_status: 1,
                type: 'view',
                onlyWithLocation: true,
                selectedAppId: this.$appId,
                selectedContentTypeId: this.$institutionId,
                keyword: '',
                language: this.$store.getters.getLanguage,
                sort: {
                  prop: '',
                  order: 'asc'
              },
          },
      },
      selectedCategory: null,
      selectedFilters: new Map(),
      expandFilter: false,
      scrollVerticalOptions: {
        container: '.main-sidebar',
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        x: false,
        y: true,
    },
    scrollHorizontalOptions: {
        container: '.horizontal-scroll',
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        x: true,
        y: false,
    },
}
},
created(){
    this.getInstitutionSettings();
    var filters = argsFilterMapToJSMap(this.uriFilters);
    if(isset(filters)){
        this.filteringFinished(filters);
        this.showMainCategorySelectModal = false;
    }
    else{
     this.showMainCategorySelectModal = true;   
 }
 this.getContents();
},
watch: {
  $screenWidth(newWidth) {
    if(newWidth<=991){
        this.isMobileView = true;
    }
    else{
        this.isMobileView = false;
    }
},
isMobileView(newValue,oldValue){
    /*resetting the filters if the view changes from mobile to desktop*/
    if(newValue!==oldValue){
        this.selectedFilters=new Map();
        this.resetAllFilters();
    }
}
},
computed: {
    uriFilters(){
        return this.$route.query.filters;
    },
    showMap() {
        if(Object.prototype.hasOwnProperty.call(this.$route.query,'show')){
            return false;
        }
        return true;
    },
    structuredFilters(){
     return getStructuredFilters(this.contentType);
 },
 allFilters(){
     return this.structuredFilters.concat(this.sublocalityFilter); 
 },
 sublocalityFilter(){
    if(this.sublocalities!==null){
     var bla = {};
     bla.field_type_id="location";
     bla.label = this.$t('search.districts');
     bla.options = []
     for(var i = 0; i < this.sublocalities.length; i++){
        bla.options.push({label:this.sublocalities[i].sublocality,value:this.sublocalities[i].sublocality});
    }
    return bla;
}
return null;
},
},
methods: {
 showLoader, 
 hideLoader,
 getSublocalities, 
 createSelectOptions,
 getAllowedValuesFromFieldType,
 setQueryFilters(){
    this.tempShowHide = false;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
},
getInstitutionSettings(val){
    this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
    this.$scrollTo();
    this.getContentType();
    this.getTheSublocalities();
},
searchWordChanged(word){
    this.dataSet.query.keyword = word;
    this.getContents();
},
getNumberOfResults(map){
    /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
    var tempFilters = new Map([...this.selectedFilters,...map]);
    /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
    this.getNumberOfContentsMatchingTheFilter(tempFilters);
},
filteringFinished(map){
    /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
    this.selectedFilters = map;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
},
resetAllFilters(){
    this.tempShowHide = false;
    this.selectedFilters = new Map();
    this.filteringFinished(this.selectedFilters);
},
getContentType() {
    this.contentType = null;
    var id = this.currentContentTypeId;
    this.loader = this.showLoader(this.loader);
    /*overwrite the existing filters*/
    this.selectedFilters = new Map();
    getCtype(id,this.$store.getters.getLanguage).then(response => {
        this.contentType = response.data;
    })
    .finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
},
async getTheSublocalities() {
    var id = this.currentContentTypeId;
    getSublocalities(this.$city,null, id).then(response => {
        this.sublocalities = response;
    });
},
getContents() {
    history.pushState({},null,this.$route.path + '?filters=' + encodeURIComponent(this.dataSet.query.filters));
    this.dataSet.query.type="view";
    var contentLoader = this.showLoader(contentLoader);
    const { limit, page } = this.dataSet.query;
    search(this.dataSet.query).then(response => {
        const { data, meta } = response;
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
      });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.tempContentCount = meta.total;
    })
    .finally(() => {
        contentLoader = this.hideLoader(contentLoader);
        this.tempShowHide = true;

    });
},
getNumberOfContentsMatchingTheFilter(tempFilters){
    this.dataSet.query.type="count";
    this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
    var contentLoader = this.showLoader(contentLoader);
    getContentsOfType(this.currentContentTypeId,this.dataSet.query, this.$store.getters.getLanguage).then(response => {
        this.tempContentCount = response;
    })
    .finally(() => {
        contentLoader = this.hideLoader(contentLoader);
    });
},
highlightThisContent(params){
 console.log("highlight");
 console.log(params);
 Object.keys(this.dataSet.data).forEach((k) => {
    this.dataSet.data[k].highlight = false;
    if(this.dataSet.data[k].id == params.content.id){
        this.dataSet.data[k].highlight = true;

        if(params.sender != "map"){
                /*open the popup*/
            this.$refs.theMap.openPopup(params.content);
        }
        else{
            if(this.$isMobileScreen){
               console.log("hier1");
               VueScrollTo.scrollTo(('#horizontal-content-'+this.dataSet.data[k].id), '2000',this.scrollHorizontalOptions);
           }
           else{
            console.log("hier2");
            console.log('#vertical-content-'+this.dataSet.data[k].id);
               VueScrollTo.scrollTo(('#vertical-content-'+this.dataSet.data[k].id), '2000',this.scrollVerticalOptions);
           }
       }
   }
});
},
}
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.search-no-results{
    margin-top: 40px;
    width: 100%; 
    text-align: center;

    img{
        width: 110px;
    }  
}

.karte{
    overflow: hidden;

    .the-karte{
        @media (max-width: 991px) {
            margin-top: 64px;
        }
    }

    .karte-cards{
        width: 100%;
    }
}

.main-sidebar__content{
    overflow-y: auto;
}

.main-sidebar__head {
    display: flex;
    align-items: center;

    .btn-group {
        margin-left: auto;
    }
}

.main-sidebar_scroll{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: calc(100vh - 320px);
    scrollbar-width: none;
}

.list-section{
    margin-top: 15px !important;
    @media (max-width: 991px) {
        padding: 0px;
        padding-top: 50px;
    }
}

.btn-group {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    border-radius: 100px;
    padding: 5px;
    margin-left: 15px;

    .btn {
        border: none;
        padding: 0 0.75rem;
        height: 30px;
        background-color: transparent;
        color: $dark-color;
        font-weight: 400;
        line-height: 30px;

        &-active {
            background-color: $primary !important;
            color: #FFF;
            border-radius: 100px !important;
            font-weight: 700;
        }
    }
}

.cards {
    @media (max-width: 1600px) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media (min-width: 992px) and (max-width: 1250px) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media (max-width: 750px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.card {
    margin-top: 20px;
}

.map-card {
    @media (max-width: 991px){
        margin-bottom: 0px !important;
    }
}

body[dir="rtl"] {
   .main-sidebar{
     background-color: #FFFF; 
 }

 .btn-group {
    margin-left: 0;
    margin-right: auto;
}
}


</style>

